import { LazyMotion, m } from 'framer-motion';

import type { ReactNode } from 'react';

const loadFeatures = () => import('./features.js').then((res) => res.default);

interface Props {
  children: ReactNode;
}

export default function MotionLazyContainer({ children }: Props) {
  return (
    <LazyMotion strict features={loadFeatures}>
      <m.div style={{ height: '100%' }}> {children} </m.div>
    </LazyMotion>
  );
}
