export interface IEvent {
  event: Event;
  category: EventCategory;
  action: EventAction;
  value: string;
}

export const tagId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ?? '';

export const pageView = (url: string): void => {
  if (typeof window === 'undefined') return;
  if (typeof window.gtag !== 'function') return;

  window.gtag('config', tagId, { page_path: url });
};

export const trackEvent = (
  event: Event,
  category: EventCategory,
  action: EventAction,
  value: string,
): void => {
  if (typeof window === 'undefined') return;
  if (typeof window.gtag !== 'function') return;

  window.gtag('event', event, { category, action, value });
};

export const mapEvent = (e: IEvent) => {
  trackEvent(e.event, e.category, e.action, e.value);
};

export enum EventAction {
  CLICK = 'Click',
  LINK = 'Link',
  MODAL = 'Modal',
}

export enum EventCategory {
  REGISTER = 'Registro',
  PRODUCTS = 'Productos',
  INTEGRATIONS = 'Integraciones',
  WORK_WITH_US = 'Trabaja con nosotros',
  P99 = 'Sobre99',
  HOME = 'Home',
}

export enum Event {
  B_AGENDARLLAMADA = 'B.AgendarLlamada',
  B_CREARCUENTA = 'B.CrearCuenta',
  B_FACEBOOK = 'B.Facebook',
  B_FINALIZAR = 'B.Finalizar',
  B_INGRESA = 'B.Ingresa',
  B_INSTAGRAM = 'B.Instagram',
  B_LINKEDIN = 'B.Linkedin',
  B_RESTREAENVIO = 'B.RestreaEnvio',
  B_SIGUIENTE = 'B.Siguiente',
  B_TIKTOK = 'B.Tiktok',
  B_WHATSAPP = 'B.Whatsapp',
  B_YOUTUBE = 'B.Youtube',
  F_API = 'F. API',
  F_BIGCOMMERCE = 'F. Bigcommerce',
  F_BLOG = 'F.Blog',
  F_CASH99 = 'F.Cash99',
  F_CONVERTIRTEVELOZ = 'F.convertirteVeloz',
  F_FREELANCE = 'F.RepartidoresFreelance',
  F_PUNTO99WORK = 'F.Punto99yCentroDeReparto',
  F_BASE_DISTRIBUTORS = 'F.RepartidoresBase',
  F_STRATEGIC_ALLIES = 'F.AliadosEstrategicos',
  F_SERVICE_PARTNER = 'F.SocioDeServicio',
  F_CULTURA = 'F.Cultura',
  F_ENVIOS99 = 'F.Envios99',
  F_FACTURACION = 'F.Facturacion',
  F_FULFILL99 = 'F.Fulfill99',
  F_JUMPSELLER = 'F. Jumpseller',
  F_MAGENTO = 'F.Magento',
  F_WOOCOMMERCE = 'F.WooCommerce',
  F_MELIFLEX = 'F.MeliFlex',
  F_PATNERSHIP = 'F.Patnership',
  F_POLITICAS = 'F.Politicas',
  F_POLITICASPROVEEDORES = 'F.PoliticasProveedores',
  F_PRESTASHOP = 'F. Prestashop',
  F_TRABAJACONNOSOTROS = 'F.TrabajaConNosotros',
  F_PUNTO99 = 'F.Punto99',
  F_RUTA99 = 'F.Ruta99',
  F_SERVICESTATUS = 'F.ServiceStatus',
  F_SHOPIFY = 'F.Shopify',
  F_SOPORTE = 'F.Soporte',
  F_SUSTENTABILIDAD = 'F.Sustentabilidad',
  F_TARIFAS = 'F.Tarifas',
  F_PROVEEDORES = 'F.Proveedores',
  F_TIENDANUBE = 'F.TiendaNube',
  F_TLM = 'F.TLM',
  F_TYC = 'F.TyC',
  F_TYCPROVEEDORES = 'F.TyCProveedores',
  F_VTEX = 'F.Vtex',
  L_PAIS = 'L.Pais',
  M_BLOG = 'M.blog',
  M_INTEGRACIONES = 'M.Integraciones',
  M_TRABAJACONNOSOTROS = 'M.TrabajaConNosotros',
  M_PRODUCTO = 'M.Producto',
  M_SOPORTE = 'M.Soporte',
  M_PROVEEDOR = 'M.Proveedor',
  M_TARIFAS = 'M.Tarifas',
  R_VOLUMEN = 'R.Volumen',
  SM_API = 'SM. API',
  SM_BIGCOMMERCE = 'SM. Bigcommerce',
  SM_CASH99 = 'SM.Cash99',
  SM_CONVERTIRTEVELOZ = 'SM.convertirteVeloz',
  SM_CULTURA = 'SM.Cultura',
  SM_ENVIOS99 = 'SM.Envios99',
  SM_FULFILL99 = 'SM.Fulfill99',
  SM_JUMPSELLER = 'SM. Jumpseller',
  SM_MAGENTO = 'SM.Magento',
  SM_WOOCOMMERCE = 'SM.WooCommerce',
  SM_MELIFLEX = 'SM.MeliFlex',
  SM_PATNERSHIP = 'SM.Patnership',
  SM_PRESTASHOP = 'SM. Prestashop',
  SM_PUNTO99 = 'SM.Punto99',
  SM_RUTA99 = 'SM.Ruta99',
  SM_SHOPIFY = 'SM.Shopify',
  SM_SUSTENTABILIDAD = 'SM.Sustentabilidad',
  SM_FREELANCE = 'SM.RepartidoresFreelance',
  SM_PUNTO99WORK = 'SM.Punto99yCentroDeReparto',
  SM_BASE_DISTRIBUTORS = 'SM.RepartidoresBase',
  SM_STRATEGIC_ALLIES = 'SM.AliadosEstrategicos',
  SM_SERVICE_PARTNER = 'SM.SocioDeServicio',
  SM_TARIFAS = 'SM.Tarifas',
  SM_TIENDANUBE = 'SM.TiendaNube',
  SM_TLM = 'SM.TLM',
  SM_VTEX = 'SM.Vtex',
  SOBRE99 = 'Sobre99',
  T_APELLIDO = 'T.Apellido',
  T_MAIL = 'T.Mail',
  T_NOMBRE = 'T.Nombre ',
  T_NOMBRENEG = 'T.NombreNeg',
  T_TEL = 'T.Tel',
}
