import Box from '@mui/material/Box';
import { m } from 'framer-motion';

import { varFade } from './variants';

import type { BoxProps } from '@mui/material';
import type { MotionProps } from 'framer-motion';

type Props = BoxProps & MotionProps;

interface TextAnimateProps extends Props {
  text: string;
}

const TextAnimate = ({ text, variants, sx, ...other }: TextAnimateProps) => {
  return (
    <Box
      component={m.div}
      sx={{
        m: 0,
        typography: 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {text.split('').map((letter, index) => (
        <m.span key={index} variants={variants || varFade().inUp}>
          {letter}
        </m.span>
      ))}
    </Box>
  );
};

export default TextAnimate;
