// @ts-nocheck
import { useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import { useSettingsContext } from '../components/settings';
import customShadows from './customShadows';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

import type { ThemeOptions } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
}

export default function ThemeProvider({ children }: Props) {
  const { themeDirection } = useSettingsContext();

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette('light'),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      breakpoints: {
        values: {
          xs: 0,
          sm: 640,
          md: 1024,
          lg: 1200,
          xl: 1920,
          xxl: 2560,
        },
      },
    }),
    [themeDirection],
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </MUIThemeProvider>
  );
}
