import { Barlow } from 'next/font/google';

interface ResponsiveFontSizesProps {
  xs: number;
  sm: number;
  md: number;
  lg: number;
}

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ xs, sm, md, lg }: ResponsiveFontSizesProps) {
  return {
    '@media (min-width:0px)': {
      fontSize: pxToRem(xs),
      lineHeight: pxToRem(xs * 1.2),
    },
    '@media (min-width:640px)': {
      fontSize: pxToRem(sm),
      lineHeight: pxToRem(sm * 1.2),
    },
    '@media (min-width:1024px)': {
      fontSize: pxToRem(md),
      lineHeight: pxToRem(md * 1.2),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
      lineHeight: pxToRem(lg * 1.2),
    },
  };
}

export const primaryFont = Barlow({
  weight: ['400', '500', '600', '700', '800'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

export const secondaryFont = Barlow({
  weight: ['900'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

const typography = {
  fontFamily: primaryFont.style.fontFamily,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 800,
    lineHeight: pxToRem(90),
    fontSize: pxToRem(75),
    ...responsiveFontSizes({ xs: 60, sm: 60, md: 75, lg: 75 }),
  },
  h2: {
    fontWeight: 800,
    lineHeight: pxToRem(72),
    fontSize: pxToRem(55),
    ...responsiveFontSizes({ xs: 40, sm: 40, md: 55, lg: 55 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: pxToRem(54),
    fontSize: pxToRem(45),
    ...responsiveFontSizes({ xs: 33, sm: 33, md: 45, lg: 45 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: pxToRem(42),
    fontSize: pxToRem(35),
    ...responsiveFontSizes({ xs: 29, sm: 29, md: 35, lg: 35 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: pxToRem(28.8),
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ xs: 22, sm: 22, md: 24, lg: 24 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: pxToRem(22.8),
    fontSize: pxToRem(19),
    ...responsiveFontSizes({ xs: 17, sm: 17, md: 19, lg: 19 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: pxToRem(28.8),
    fontSize: pxToRem(24),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: pxToRem(22.8),
    fontSize: pxToRem(19),
  },
  body1: {
    lineHeight: pxToRem(20.4),
    fontSize: pxToRem(17),
  },
  body2: {
    lineHeight: pxToRem(16.8),
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: pxToRem(14.4),
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: pxToRem(19.2),
    fontSize: pxToRem(16),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: pxToRem(19.2),
    fontSize: pxToRem(16),
    textTransform: 'unset',
  },
};

export default typography;
