import Box from '@mui/material/Box';
import { m } from 'framer-motion';

import useResponsive from '@/hooks/useResponsive';

import { varContainer } from './variants';

import type { ReactNode } from 'react';

interface MotionProps {
  children?: ReactNode;
  disableAnimatedMobile?: boolean;
}

const MotionViewport = ({ children, disableAnimatedMobile = true, ...other }: MotionProps) => {
  const isMobile = useResponsive('down', 'sm');

  if (isMobile && disableAnimatedMobile) {
    return <Box {...other}>{children}</Box>;
  }

  return (
    <Box
      component={m.div}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true, amount: 0.3 }}
      variants={varContainer()}
      {...other}
    >
      {children}
    </Box>
  );
};

export default MotionViewport;
