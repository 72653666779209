import { forwardRef } from 'react';

import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';

import type { BoxProps } from '@mui/material';

import type { IconifyProps } from './types';

interface Props extends BoxProps {
  icon: IconifyProps;
}

const Iconify = forwardRef<SVGElement, Props>(function Iconify(
  { icon, width = 20, sx, ...other },
  ref,
) {
  return (
    <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
  );
});

export default Iconify;
