// @ts-nocheck

import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { MotionLazyContainer } from '@/components/animate';
import ProgressBar from '@/components/progress-bar/ProgressBar';
import { SettingsProvider } from '@/components/settings';
import ThemeLocalization from '@/locales/ThemeLocalization';
import { useLayoutStore } from '@/stores/layout/layout.store';
import ThemeProvider from '@/theme';
import createEmotionCache from '@/utils/createEmotionCache';
import { getCountryName } from '@/utils/services/axiosRequest';

import type { EmotionCache } from '@emotion/react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import 'i18n/i18nConfig.ts';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-quill/dist/quill.snow.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

import useGTAG from '@/hooks/useGTAG';

const LazyChatBot = dynamic(async () => await import('@/utils/ChatBot'), { ssr: false });

const clientSideEmotionCache = createEmotionCache();

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface LandingProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

const validCountries = ['MX', 'CL', 'CO', 'PE'];

const App = (props: LandingProps) => {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;
  const getLayout = Component.getLayout ?? ((page) => page);
  const setCountry = useLayoutStore((state) => state.setCountry);
  const setCountryName = useLayoutStore((state) => state.setCountryName);

  useGTAG();

  const getGeoInfo = async () => {
    const response = await getCountryName();

    if (validCountries.includes(response.country_code)) {
      setCountry(response.country_code);
      setCountryName(formatName(response.country_name));
    } else {
      setCountry('MX');
      setCountryName('México');
    }
  };

  useEffect(() => {
    const actualUrl = window.location.href;

    // Si url actual incluye .cl o testchl setea el país a Chile
    if (actualUrl.includes('.cl') || actualUrl.includes('testchl')) {
      setCountry('CL');
      setCountryName('Chile');
    } else {
      void getGeoInfo();
    }

    const handleColorSchemeChange = (event: MediaQueryListEvent) => {
      const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;
      favicon.href = event.matches ? '/favicon/favicon.svg' : '/favicon/99-32x32.ico';
    };

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;

    favicon.href = mediaQuery.matches ? '/favicon/favicon.svg' : '/favicon/99-32x32.ico';

    mediaQuery.addEventListener('change', handleColorSchemeChange);

    return () => {
      mediaQuery.removeEventListener('change', handleColorSchemeChange);
    };
  }, []);

  const route = useRouter();

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />

        {/* Open Graph Tags */}
        <meta property="og:site_name" content="99minutos" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="es_MX" />

        {environment === 'dev' && (
          <>
            <meta name="robots" content="noindex,nofollow" />
            <meta name="googlebot" content="noindex,nofollow" />
          </>
        )}
      </Head>

      {route.pathname !== '/sitemap.xml' && <LazyChatBot />}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <MotionLazyContainer>
            <ThemeProvider>
              <ThemeLocalization>
                <ProgressBar />
                {getLayout(<Component {...pageProps} />)}
              </ThemeLocalization>
            </ThemeProvider>
          </MotionLazyContainer>
        </SettingsProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

const formatName = (countryName: string) => {
  const countries: Record<string, string> = {
    Mexico: 'México',
    Peru: 'Perú',
  };

  return countries[countryName] ? countries[countryName] : countryName;
};

export default App;
