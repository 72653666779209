import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationESMX from './es/es-MX.json';

const resources = {
  'es-MX': {
    translation: translationESMX,
  },
};

void i18n.use(initReactI18next).init({
  resources,
  lng: 'es-MX',

  keySeparator: '.',

  interpolation: {
    escapeValue: true,
  },

  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'em', 'i', 'b', 'a', 'strong', 'span'],
  },
});

export default i18n;
