import { create } from 'zustand';

import type { Article } from '@/@types/articles';

interface LayoutStoreProps {
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  country: string;
  setCountry: (value: string) => void;
  countryName: string;
  setCountryName: (value: string) => void;
  submenu: string;
  setSubmenu: (value: string) => void;
  article: Article;
  setArticle: (value: Article) => void;
  articleItems: Article[];
  setArticleItems: (value: Article[]) => void;
  articleId: string;
  setArticleId: (value: string) => void;
}

const initialState = {
  menuOpen: false,
  country: 'MX',
  countryName: '',
  submenu: '',
  article: {
    image: '',
    date: '',
    description: '',
    category: '',
    body: '',
  },
  articleItems: [],
  articleId: '',
};

export const useLayoutStore = create<LayoutStoreProps>((set, get) => ({
  ...initialState,
  setMenuOpen: (value: boolean) => set({ menuOpen: value }),
  setCountry: (value: string) => set({ country: value }),
  setCountryName: (value: string) => set({ countryName: value }),
  setSubmenu: (value: string) => set({ submenu: value }),
  setArticle: (value: Article) => set({ article: value }),
  setArticleItems: (value: Article[]) => set({ articleItems: value }),
  setArticleId: (value: string) => set({ articleId: value }),
}));
