import { forwardRef, useMemo } from 'react';

import { Box, IconButton } from '@mui/material';
import { m } from 'framer-motion';

import type { IconButtonProps } from '@mui/material';
import type { ReactNode } from 'react';

interface IconButtonAnimateProps extends Omit<IconButtonProps, 'size'> {
  children: ReactNode;
  size?: 'small' | 'medium' | 'large';
}

interface AnimateWrapProp {
  children: ReactNode;
  size: 'small' | 'medium' | 'large';
}

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonAnimateProps>(
  function IconButtonAnimate({ children, size = 'medium', ...other }, ref) {
    return (
      <AnimateWrap size={size}>
        <IconButton size={size} ref={ref} {...other}>
          {children}
        </IconButton>
      </AnimateWrap>
    );
  },
);

const AnimateWrap = ({ size, children }: AnimateWrapProp) => {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  const variant = useMemo(() => {
    if (isSmall) return varSmall;
    if (isLarge) return varLarge;
    return varMedium;
  }, [isSmall, isLarge]);

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={variant}
      sx={{ display: 'inline-flex' }}
    >
      {children}
    </Box>
  );
};

export default IconButtonAnimate;
