import { useMemo } from 'react';

import { alpha, createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import merge from 'lodash/merge';

import { useSettingsContext } from './SettingsContext';

interface ThemeColorProps {
  children: React.ReactNode;
}

const ThemeColorPresets = ({ children }: ThemeColorProps) => {
  const outerTheme = useTheme();

  const { presetsColor } = useSettingsContext();

  const themeOptions = useMemo(
    () => ({
      palette: {
        primary: presetsColor,
      },
      customShadows: {
        primary: `0 8px 16px 0 ${alpha(presetsColor.main, 0.24)}`,
      },
    }),
    [presetsColor],
  );

  const theme = createTheme(merge(outerTheme, themeOptions));

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeColorPresets;
