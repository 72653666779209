import { alpha } from '@mui/material/styles';

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  main: '#9697AF',
  50: '#F2F3F5',
  100: '#E0E0E7',
  200: '#CBCBD7',
  300: '#B6B6C7',
  400: '#A6A7BB',
  500: '#9697AF',
  600: '#8E8FA8',
  700: '#83849F',
  800: '#797A96',
  900: '#686986',
};

const PRIMARY = {
  main: '#85C440',
  50: '#F0F8E8',
  100: '#DAEDC6',
  200: '#C2E2A0',
  300: '#AAD679',
  400: '#97CD5D',
  500: '#85C440',
  600: '#7DBE3A',
  700: '#72B632',
  800: '#68AF2A',
  900: '#55A21C',
  darker: '#55A21C',
  dark: '#7DBE3A',
  light: '#DAEDC6',
  lighter: '#F0F8E8',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  light: '#F5F5F5',
  main: '#12344A',
  dark: '#262927',
  darker: '#000000',
  lighter: '#F5F5F5',
  contrastText: '#FFFFFF',
};

const SKY = {
  main: '#ADD8E5',
};

const MORA = {
  main: '#12344A',
};

const MELON = {
  main: '#FCB970',
};

const SANDIA = {
  main: '#FB728C',
};

const LIMON = {
  main: '#3FC468',
};

const MENTA = {
  main: '#20C9A7',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#FFFFFF',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: { black: '#000000', white: '#FFFFFF' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  sky: SKY,
  melon: MELON,
  mora: MORA,
  sandia: SANDIA,
  limon: LIMON,
  menta: MENTA,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#FFFFFF', default: '#FFFFFF', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return themeMode === 'light' ? light : dark;
}
