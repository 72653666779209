import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { pageView } from '@/utils/goggleAnalytics/gtag';

const useGTAG = () => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView);
    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router.events]);

  return {};
};

export default useGTAG;
