import { forwardRef, useMemo } from 'react';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import { m } from 'framer-motion';

import type { FabProps, SxProps } from '@mui/material';
import type { HTMLAttributeAnchorTarget } from 'react';

export interface FabButtonAnimateOutlinedProps extends FabProps {
  sxWrap?: SxProps;
  target?: HTMLAttributeAnchorTarget;
}

interface AnimateWrapProp {
  children: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  sxWrap?: SxProps;
}

const varSmall = {
  hover: { scale: 1.07 },
  tap: { scale: 0.97 },
};

const varMedium = {
  hover: { scale: 1.06 },
  tap: { scale: 0.98 },
};

const varLarge = {
  hover: { scale: 1.05 },
  tap: { scale: 0.99 },
};

const FabButtonAnimateOutlined = forwardRef<HTMLButtonElement, FabButtonAnimateOutlinedProps>(
  function FabButtonAnimateOutlined({ size = 'large', children, sx, sxWrap, ...other }, ref) {
    const theme = useTheme();

    return (
      <AnimateWrap size={size} sxWrap={sxWrap}>
        <Fab
          ref={ref}
          size={size}
          sx={{
            minWidth: '5vh',
            width: 'max-content',
            mx: '0.4vh',
            color: 'secondary.main',
            fontSize: size === 'large' ? '1rem' : '0.95rem',
            fontWeight: theme.typography.fontWeightMedium,
          }}
          style={{
            borderWidth: '0.3vh',
          }}
          variant="outlined"
          {...other}
        >
          {children}
        </Fab>
      </AnimateWrap>
    );
  },
);

const AnimateWrap = ({ size, children, sxWrap }: AnimateWrapProp) => {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  const variant = useMemo(() => {
    if (isSmall) return varSmall;
    if (isLarge) return varLarge;
    return varMedium;
  }, [isSmall, isLarge]);

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={variant}
      sx={{ display: 'inline-flex', ...sxWrap }}
    >
      {children}
    </Box>
  );
};

export default FabButtonAnimateOutlined;
