import { useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import merge from 'lodash/merge';

import { useSettingsContext } from './SettingsContext';

interface ContrastProps {
  children: React.ReactNode;
}

const ThemeContrast = ({ children }: ContrastProps) => {
  const outerTheme = useTheme();

  const { themeContrast, themeMode } = useSettingsContext();

  const isLight = themeMode === 'light';

  const isContrastBold = themeContrast === 'bold';

  const themeOptions = useMemo(
    () => ({
      palette: {
        background: {
          ...(isContrastBold && {
            default: isLight ? outerTheme.palette.grey[100] : outerTheme.palette.grey[900],
          }),
        },
      },
      components: {
        MuiCard: {
          styleOverrides: {
            ...(isContrastBold && {
              root: {
                boxShadow: outerTheme.customShadows.z4,
              },
            }),
          },
        },
      },
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLight, themeContrast],
  );

  const theme = createTheme(merge(outerTheme, themeOptions));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeContrast;
